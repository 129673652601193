@import "../../../../styles/base/Colors.scss";

.card__template-whatsapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  background: $white2;
  border: 0.5px solid $gray5;
  border-radius: 10px;
  padding: 3px;

  .header__template-whatsapp {
    width: 100%;
    height: auto;
    overflow: hidden;
    .category__overlay {
      background: $darkblue;
      color: $white;
      padding: 2px 5px;
      border-radius: 5px;
      width: 30%;
      font-weight: 600;
      text-align: center;
      margin: 2px 0px;
    }

    .img__template-whatsapp {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 10px 10px 0px 0px;
    }
  }

  .main__template-whatsapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 0px 10px;
    margin: 10px 0px 0px 0px;

    .text__template-whatsapp {
      color: $gray1;
      text-align: justify;
      font-size: 13.5px;
      font-weight: 400;
      line-height: 12px;
      margin: 10px 0px;
    }
    .time__template-whatsapp {
      color: $gray3;
      font-size: 12px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .footer__template-whatsapp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    border-top: 1px solid $gray4;
    width: 100%;
    padding-top: 10px;

    .arrow-icon__template-whatsapp {
      width: 20px;
      fill: $zenblue;
    }
    .button__template-whatsapp {
      font-size: 15px;
      text-decoration: none;
      color: $zenblue;
    }
  }
}
.div__overlay-and-dropdown {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .category__overlay {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .dropdown-icon {
    cursor: pointer;
    margin-left: 10px;
    width: 30px;
  }

  .dropdown-content {
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;

    button {
      border: none;
      background: none;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
