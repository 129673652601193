@import "../styles/utils/Mixins.scss";
@import "../styles/base/Colors.scss";

.text__section {
  margin-top: 70px;
  padding-top: 50px;
}

.text__section,
.ul__values {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  justify-content: center;
  height: 100%;
  margin-bottom: 80px;
  color: $zenblue;

  > li {
    color: $zenblue;

    > p {
      color: $gray2;
    }
  }

  > h1 {
    width: 100%;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
    color: $zenblue;
  }

  > h2 {
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
  }

  > h3 {
    text-align: left;
    margin-bottom: 20px;
    text-align: justify;
    color: $gray1;

    @include mq(mobile) {
      margin-left: 50px;
    }
  }

  > p {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $gray2;

    @include mq(mobile) {
      margin-left: 50px;
    }
  }

  > article {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > ul {
    > li {
      width: 100%;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
.text__main {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: $white;
  gap: 50px;
  @include mq(desktop) {
    justify-content: space-evenly;
  }
}
