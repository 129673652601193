@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "./styles/base/reset.scss";
@import "./styles/utils/Variables.scss";
@import "./components/Buttons/Buttons.scss";
@import "./components/Images/Images.scss";
@import "./styles/base/typography.scss";
@import "./styles/base/Colors.scss";
@import "./styles/Themes/Dark.scss";
@import "./styles/Themes/Light.scss";
@import "./styles/utils/Mixins.scss";

$color_1: var(--color-gray-1);
$color_2: var(--background-center-white);
$color_3: var(--font-color-text-1-gray);
$color_4: var(--zen-color-green);
$background-color_1: var(--background-main-light-blue);
$background-color_2: var(--background-main-light-blue);

code {
  font-family: "Inter", sans-serif;
}

button {
  cursor: pointer;
}
