@import "../../styles/base/Colors.scss";
@import "../../styles/utils/Mixins.scss";

.homepage-body {
  display: flex;
  align-items: stretch;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.content {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px solid var(--shadow-div-center);
  background-image: url("../../assets/homePage.webp");
  background-position: center;
  background-repeat: no-repeat;
}

.div__button-main {
  margin-top: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  height: 5rem;
}

img {
  width: 100px;
}

.div__slogan {
  background-color: $white50;
  border-radius: 20px;
  padding: 2em;
  width: 90%;

  > h2 {
    width: 100%;
    color: $darkblue;
    align-self: center;
  }

  > p {
    width: 100%;
    margin-top: 1em;
    text-align: justify;
    color: $gray4;
    align-self: center;
  }
}

Footer {
  display: none;

  @include mq(desktop) {
    display: flex;
  }
}
.video-container {
  margin-top: 50px;
  max-width: 100%;
}

iframe {
  width: 373px;

  height: 210px;
  border-radius: 15px;
}
