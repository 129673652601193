@import "../../../styles/utils/Mixins.scss";
@import "../../../styles/base/Colors.scss";

$margin: 1rem 0rem;
$color_1: var(--color-gray-2);
$color_2: #333;
$color_3: var(--background-center-white);
$font-family_1: "Inter", sans-serif;
$font-family_2: "Nunito Sans", sans-serif;
$background-color_1: var(--zen-color-blue);
$background-color_2: var(--background-center-white);

.div__signup {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  font-family: $font-family_1;
  font-family: $font-family_2;
  height: 100vh;
  background: #f6f9fa;
  padding: 50px 0px 200px 0px;
  width: 90vw;
}
.header__signUp {
  padding: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  border-bottom: 1px solid $zenblue;
}
.img__logo--signup {
  align-self: center;
}
.div__logo--signUp {
  width: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}
.container {
  width: 70vw;
  text-align: center;
  margin-top: 260px;
  height: 100%;
  align-items: center;
}
#byClicking {
  white-space: nowrap;
  a {
    white-space: inherit;
  }
}
.h1__signUp {
  font-size: var(--font-size-title-2);
  font-weight: var(--font-size-weight-1);
  text-wrap: nowrap;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $zenblue;
}
.article__signUp {
  font-size: var(--font-size-text-4);
  line-height: var(--line-height-1);
  margin-bottom: 1rem;
}
.input__container {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.input__container > div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  @include mq(desktop) {
    flex-direction: row;
    justify-content: center;
  }
}
.input__container > label {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  flex-direction: row;
  width: 100%;
}
#name,
#surname {
  @include mq(desktop) {
    width: 150px;
    min-width: 150px;
  }
}
input {
  max-width: 340px;
  min-width: 250px;
  height: 1.5rem;
  padding: 10px;
  border: 1px solid $zenblue;
  color: $color_1;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  &:focus + label {
    top: -10px;
    left: 0;
    font-size: 12px;
    color: $color_2;
  }
  &:not(:placeholder-shown) + label {
    top: -10px;
    left: 0;
    font-size: 12px;
    color: $color_2;
  }
  @include mq(desktop) {
    width: 100%;
  }
}
label {
  gap: 15px;
  width: 80%;
  max-width: 80%;
}
.input__checkbox {
  width: 20px;
  min-width: 20px;
  cursor: pointer;
}
label > p {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 180px;
  font-size: var(--font-size-button-1);
  border: none;
  height: 40px;
  background-color: $background-color_1;
  color: $color_3;
  font-size: 20px;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: $background-color_1;
  }
}
p {
  text-decoration: none;
}
.div__already-have-account {
  margin: $margin;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  @include mq(desktop) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.div__already-have-account > p {
  text-wrap: nowrap;
}
.div__name {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 85%;
}
.container__or {
  margin: 10px 0px 10px 0px;
  width: 90%;
  display: flex;
  align-items: center;
  @include mq(desktop) {
    margin: $margin;
  }
}
.container__alternative {
  > a {
    gap: 1em;
    padding: 0em 1em;
    border: 1px solid $zenblue;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 3rem;
    align-items: center;
    justify-content: flex-start;
    color: #2e739e;
  }
}
.icon__signup {
  width: 1.5rem;
}
footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include mq(320) {
    flex-direction: row;
  }
}

.btn-google {
  display: flex;
  margin: $margin;
  align-items: center;
  align-self: center;
  justify-content: center;
  background: #f6f9fa;
  color: var(--color-gray-2);
  border-radius: 10px;
  border: 1px solid $zenblue;
  font-size: 15px;
  font-weight: 600;
  padding: 5px;
  gap: 10px;
  @include mq(desktop) {
    width: 250px;
  }
}
.p_divisor {
  color: $blue50;
}
