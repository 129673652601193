@import "../../styles/utils/ZIndex.scss";

:root {
  --logo-size: 200px 20.8px;
  --logo-size-mobile: 150px;
}

/*----------------------------------------------------
|                     Pictures                        |
----------------------------------------------------*/
.img__logo {
  width: 100px;
  position: fixed;
  top: 10px;
}

.img__logo2 {
  display: none;
}

.img__logo--signup {
  width: 5rem;
}

.img__main-page {
  width: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.img__main-page-index {
  width: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.img__background {
  display: none;
  width: auto;
  width: 30vw;
  margin-top: 5rem;
  @include z-index(backgroundImg);
}

@media screen and (min-width: 480px) {
  .img__main-page {
    width: clamp(250px, 300px, 350px);
  }
}

@media screen and (min-width: 768px) {
  .img__main-page {
    width: clamp(350px, 400px, 450px);
  }

  .img__logo {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .img__background {
    display: flex;
  }

  .img__main-page {
    display: flex;
    position: fixed;
    right: 120px;
    top: 130px;
    width: 35vw;
    @include z-index(backgroundImg);
  }

  .img__main-page-index {
    display: none;
  }

  .img__logo2 {
    display: flex;
    width: 250px;
    position: fixed;
    left: 30px;
    bottom: 30px;
  }
}
