:root {
  /*Background*/
  --brackground-main-light-blue: #b4e6f0;
  --background-center-white: #f6f9fa;
  --background-nav-center: #619ed4;
  --background-nav-border: #61bdd2;
  --background-white-2: #ffffff;
  /*Font color*/
  --font-color-title-1-dark: #2e739e;
  --zen-color-blue: #559eac;
  --zen-color-lightblue: #5daace;
  --zen-color-darkblue: #24669a;
  --zen-color-green: #c4dbcd;
  --font-color-title-2-white: #ffffff;
  --font-color-text-2-lightblue: #bfe3f4;
  /*Gray Colors*/
  --color-gray-1: #191c1f;
  --color-gray-2: #464747;
  --color-gray-3: #78797a;
  --color-gray-4: #adb5bd;
  --color-gray-5: #f4f4f5;
  --font-color-text-1-gray: #a7b4bb;

  /*Other*/
  --shadow-div-center: #71bddf;
  --transparent-white: rgb(255, 255, 255, 0.7);
  --transparent-lightblue: rgb(93, 170, 206, 0.2);
}

$lightblue: var(--zen-color-lightblue);
$white: var(--background-center-white);
$white2: var(--background-white-2);
$white50: var(--transparent-white);
$zenblue: var(--zen-color-blue);
$gray1: var(--color-gray-1);
$gray2: var(--color-gray-2);
$gray3: var(--color-gray-3);
$gray4: var(--color-gray-4);
$gray5: var(--color-gray-5);
$darkblue: var(--zen-color-darkblue);
$blue50: var(--transparent-lightblue);
