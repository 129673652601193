@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/base/typography.scss";
@import "../../../../styles/utils/Mixins.scss";

.DashboardAside {
  width: 200px;
  background-color: $gray5;
  color: white;
  padding: 15px;
  margin-top: 55px;
  padding-top: 50px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @include mq(desktop) {
    display: flex;
  }

  .div__zen-diamonds {
    display: flex;
    @include start-row;
    gap: 10px;
    margin-left: 10px;
    background-color: $white;
    border: 2px solid $zenblue;
    border-radius: 20px;
    padding: 0px 20px;

    .img__zen-diamond {
      width: 30px;
      height: 40px;
    }

    .h2__zen-diamonds-counter {
      color: $gray3;
      font-size: 30px;
    }
  }

  .Links__dashboard-aside {
    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
      color: $gray3;
      text-decoration: none;
    }

    a:hover {
      color: $gray2;
    }
  }
}

.buttonAside {
  width: 100%;
  border: none;
  background: none;
  width: 50px;
  cursor: pointer;
}

.aside-icon {
  width: 20px;
  margin: 0 10px;
  cursor: pointer;
}