@import "../../../../styles/base/Colors.scss";

.market {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0px;
    .market__title {
      font-size: 30px;
      padding: 50px;
      font-weight: 550;
      color: $gray3;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    &__card {
      border: 1px solid $white2;
      border-radius: 10px;
      padding: 10px;
      width: 200px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      text-align: center;

      &__image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin: 0 auto;
        border-radius: 10px;
      }

      &__info {
        h2 {
          font-size: 20px;
          margin: 10px 0;
          font-weight: 600;
          color: $gray2;
        }

        p {
          font-size: 14px;
          margin: 5px 0;
        }

        button {
          background-color: $lightblue;
          color: white;
          padding: 0px 20px;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          margin: 0 auto;
          font-size: 20px;

          &:hover {
            background-color: $darkblue;
          }
        }
      }
    }
  }
}
