@import "../../../../styles/utils/Mixins.scss";
@import "../../../../styles/utils/ZIndex.scss";
@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/base/typography.scss";

.modal-container {
  padding: 50px 0px 50px 50px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: 10px;

  @include mq(desktop) {
    padding: 50px 0px;
  }
}

.modal-content {
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: $white;
  padding: 30px;
  width: 350px;

  .modal-title {
    font-size: 2.2rem;
    font-weight: 600;
    color: $darkblue;
    margin-bottom: 20px;
  }

  .modal-details {
    font-size: 1.3rem;
    font-weight: 600;
    color: $gray1;
    margin: 5px 0 20px;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    button {
      padding: 10px 20px;
      font-weight: 500;
      font-size: 1rem;
      color: $white;
      background-color: $zenblue;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: $darkblue;
      }
    }
  }

  .modal-input {
    width: 100%;
    margin: 10px 0;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid $gray5;
    border-radius: 5px;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  color: $zenblue;

  &:hover {
    color: $darkblue;
  }
}
