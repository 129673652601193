@import "../../../styles/utils/Mixins.scss";
@import "../../../styles/base/Colors.scss";

$color_1: var(--color-gray-2);
$color_2: #333;
$color_3: var(--background-center-white);
$font-family_1: "Inter", sans-serif;
$font-family_2: "Nunito Sans", sans-serif;
$background-color_1: var(--zen-color-blue);
$background-color_2: var(--background-center-white);

.div__signin {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  font-family: $font-family_1;
  font-family: $font-family_2;
  height: 100vh;
  background: #f6f9fa;
  padding: 50px 0px 200px 0px;
  width: 90vw;
  @include mq(mobile-large) {
    justify-content: flex-start;
  }
}
.header__login {
  padding: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $white;
}
.img__logo--signup {
  align-self: center;
}
.div__logo--signUp {
  width: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}
.container-login {
  margin-top: 150px;
  width: 28vw;
  text-align: center;
}
#byClicking {
  white-space: nowrap;
  a {
    white-space: inherit;
  }
}
.h1__login {
  font-size: var(--font-size-title-2);
  font-weight: var(--font-size-weight-1);
  text-wrap: nowrap;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $zenblue;
}
article {
  font-size: var(--font-size-text-4);
  line-height: var(--line-height-1);
  margin-bottom: 1rem;
}
.input__container {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
label {
  top: 20px;
  left: 10px;
  font-size: 16px;
  color: $color_1;
  transition: all 0.2s ease-out;
}
input {
  width: 80%;
  height: 1.5rem;
  padding: 10px;
  border: 1px solid var(--zen-color-blue);
  color: $color_1;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  &:focus + label {
    top: -10px;
    left: 0;
    font-size: 12px;
    color: $color_2;
  }
  &:not(:placeholder-shown) + label {
    top: -10px;
    left: 0;
    font-size: 12px;
    color: $color_2;
  }
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 180px;
  font-size: var(--font-size-button-1);
  border: none;
  height: 40px;
  background-color: $background-color_1;
  color: $color_3;
  font-size: 20px;
  cursor: pointer;
  border-radius: 10px;
  margin: 0;
  &:hover {
    background-color: $background-color_1;
  }
}
p {
  text-decoration: none;
}
.div__already-have-account {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
}
.div__already-have-account > a {
  text-wrap: nowrap;
}
.div__name {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 85%;
}
.container__or-login {
  width: 50px;
  margin: 1rem 0rem;
}
.container__alternative {
  > a {
    gap: 1em;
    padding: 0em 1em;
    border: 1px solid var(--zen-color-blue);
    border-radius: 5px;
    margin-bottom: 0.5rem;
    width: 100%;
    height: 3rem;
    align-items: center;
    justify-content: flex-start;
    color: #2e739e;
  }
}
.icon__signup {
  width: 1.5rem;
}

.btn-google {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f9fa;
  color: var(--color-gray-2);
  border-radius: 10px;
  border: 1px solid var(--zen-color-blue);
  font-size: 15px;
  font-weight: 600;
  padding: 5px;
  gap: 10px;
  width: 250px;
}
footer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.p_divisor {
  color: $blue50;
}

.css-1i9p219-MuiPaper-root-MuiAlert-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #d32f2f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  color: #fff;
  width: 100%;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  display: none;
}
