@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/utils/ZIndex.scss";

.spinner-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  @include z-index(spinner);
}

.spinner-container {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border-left-color: $lightblue;
  animation: spin 0.75s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
