@use "sass:map";

$z-indexes: (
  spinner: 1001,
  modal: 1000,
  overlay: 800,
  dropdown: 700,
  header: 500,
  footer: 500,
  base: 1,
  reminders: 0,
  backgroundImg: -2,
);

@mixin z-index($name) {
  @if type-of($name) == number {
    z-index: $name;
  } @else if map-has-key($z-indexes, $name) {
    z-index: map-get($z-indexes, $name) !important;
  } @else {
    @warn "No z-index found for `#{$name}`. Please add it to the \$z-indexes map.";
  }
}
