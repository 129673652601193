@import "../../styles/utils/Mixins.scss";
@import "../../styles/utils/ZIndex.scss";
@import "../../styles/base/Colors.scss";
@import "../../styles/base/typography.scss";

.container__header {
  display: flex;
  @include between-row;
  height: 100px;
  padding: 0px 10px 0px 10px;
  position: fixed;
  top: 0;
  background: $white;
  border-bottom: 1px solid $lightblue;
  width: 100%;

  @include mq(desktop) {
    height: 55px;
  }
}

.div__logo-and-name,
.button__home {
  display: flex;
  @include between-row;
  gap: 1rem;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;

  > h2 {
    color: $lightblue;
    text-align: center;
  }

  @include mq(desktop) {
    justify-content: flex-start;
  }
}

.header__paralel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include z-index(header);
}

.nav__header {
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 1024px) {
  header {
    height: 75px;
  }
}

.user-info {
  display: flex;

  @include mq(mobile) {
    display: flex;
    @include between-row;
  }

  a {
    display: flex;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
  }

  h2 {
    color: $lightblue;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    display: none;

    @include mq(mobile) {
      display: flex;
    }
  }

  img {
    border-radius: 20px;
    display: none;

    @include mq(mobile) {
      display: flex;
    }
  }
}
