@use "sass:map";

$breakpoints: (
  mobile: 400px,
  mobile-large: 600px,
  tablet: 800px,
  desktop: 1024px,
);

@mixin mq($key) {
  @if map.has-key($breakpoints, $key) {
    $size: map.get($breakpoints, $key);

    @media (min-width: (($size)+1)) {
      @content;
    }
  } @else {
    @media (min-width: #{($key)+1}px) {
      @content;
    }
  }
}

@mixin between-column {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@mixin center-column {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin start-column {
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

@mixin between-row {
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

@mixin start-row {
  justify-content: flex-start;
  align-items: center;
  flex-direction: row !important;
}
