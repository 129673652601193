@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/base/typography.scss";
@import "../../../../styles/utils/Mixins.scss";
@import "../../../../styles/utils/ZIndex.scss";

.container__dashboardHeader {
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0px 15px;
  top: 0;
  left: 0;
  background: $lightblue;
  border-bottom: 1px solid $white;
  @include z-index(header);

  @include mq(desktop) {
    height: 55px;
  }
}

.headerLeft {
  display: flex;
  flex-direction: row;
  @include z-index(modal);
}

.hamburger {
  background: none;
  border: none;

  @include mq(mobile-large) {
    display: none;
  }
}

.hamburgerIcon {
  border: none;
  cursor: pointer;
  width: 40px;
}

.imgHamburger {
  width: 40px;
}

.button__dashboard-home,
.buttonUser-dashboard {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
  margin: 0 10px;
  border: none;
  background: transparent;
  @include z-index(modal);

  h2 {
    font-size: var(--font-size-text-3);
    line-height: var(--line-height-title-3);
    font-weight: var(--font-weight-title-2);
    text-decoration: none;
    color: $white;
  }
}

.img__logo--dashboard {
  width: 45px;
}

.buttonUser {
  border-radius: 10px;
  border: none;
  background: none;
  width: 40px;
  display: none;
  cursor: pointer;

  @include mq(mobile) {
    display: flex;
  }

  img {
    width: 35spx;
    border-radius: 20px;
  }
}
