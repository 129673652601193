@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/utils/Mixins.scss";
@import "../../../../styles/base/typography.scss";

.modalAside {
  position: fixed;
  top: 80px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq(tablet) {
    left: 100px;
  }

  .modalAside-content {
    position: relative;
    background: $white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: 2px solid $darkblue;
    width: 200px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    .modalAside-item {
      display: block;
      color: $darkblue;
      text-decoration: none;
      font-weight: 600;
      font-size: $size7;
      background: none;
      border: none;
    }
  }
}