@import "../../styles/utils/Mixins.scss";
@import "../../styles/base/Colors.scss";

footer {
  position: static;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div__mother {
  background-color: var(--background-nav-center);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  bottom: 0;
  height: 15vh;
  justify-content: space-around;
  gap: 5px;
  @include mq(mobile-large) {
    height: 10vh;
    @include between-row;
  }
}

.div__btn-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 15vh;
  width: 100%;

  @include mq(mobile-large) {
    flex-direction: row;
  }
}

.btn__footer {
  color: var(--font-color-title-2-white);
  text-decoration: none;
}
