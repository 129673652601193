@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/utils/Mixins.scss";
@import "../../../../styles/base/typography.scss";

.modalVerification {
  display: flex;
  justify-content: center;
  align-items: center;

  .modalVerification-content {
    background: $white;
    padding: 20px 10px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border: 2px solid $darkblue;
    width: 200px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-verification {
      max-width: 100%;
      min-width: 150px;
    }
    .modalVerification-item {
      display: block;
      color: $darkblue;
      text-decoration: none;
      font-weight: 600;
      font-size: $size7;
      background: none;
      border: none;
    }
  }
}
.resendCodeButton {
  color: $gray3;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-family: inherit;
}
