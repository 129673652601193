@import "../../../styles/utils/Mixins.scss";
@import "../../../styles/base/Colors.scss";
@import "../../../styles/base/typography.scss";

.main-dashboard-pages {
  margin-top: 50px;

  @include mq(desktop) {
    margin-left: 250px;
  }
}

.dash-page-container {
  width: 100%;
  height: 100%;

  @include mq(desktop) {
    padding-left: 50px;
  }
}

.deletePhone {
  border: none;

  .phone-nav-icon {
    width: 30px;
    cursor: pointer;
  }
}

.verifyOutlet {
  gap: 10px;
  margin: 150px 10px 20px 10px;
  display: flex;
  @include center-column;
}

.verifyTitle {
  color: $darkblue;
  font-size: $size3;
  font-weight: 500;
  margin-bottom: 30px;
}

.PhoneInput {
  display: flex;
  flex-direction: row;
}

.PhoneInputCountry {
  align-items: center;
  justify-content: center;
}

.PhoneInputCountrySelectArrow {
  display: none;
}

.reminders {
  @include start-column;
  padding-left: 0px;
}

.payments-main-div {
  padding-bottom: 50px;
  width: 75vw;
  background-color: $white;
  margin-top: 150px;
  display: flex;
  @include center-column;
}

.reminders,
.verifyOutlet,
.profile-card,
.verifiedNumbersContainer {
  max-width: 95%;
  background-color: $white;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  h1 {
    font-size: 020px;
    font-weight: 500;
    color: $darkblue;
    margin: 30px 0px;
  }

  h2 {
    font-size: $size3;
    font-weight: 500;
    color: $darkblue;
    margin: 30px 0px;
  }

  .ulTasks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    .liTask {
      color: $gray2;
      font-size: 15px;
      display: flex;
      border-radius: 10px;
      padding: 10px 20px;
      @include start-row;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }
  }
}

.profile-card {
  display: flex;
  flex-direction: column;
}

.profile-picture {
  border-radius: 10px;
  border: 2px solid $zenblue;
  height: 150px;
  width: 150px;
}

.user-data {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    font-size: 20px;
  }
}

.icon-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-default {
  width: 30px;
  margin: 0 10px;
  cursor: pointer;
}

.profileEditIcon {
  position: fixed;
  right: 60px;
  top: 120px;
  width: 30px;
  cursor: pointer;

  &hover {
    width: 40px;
    right: 55px;
    top: 115px;
  }
}

.payments-main-div {
  h1 {
    color: $zenblue;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 50px;
  }
}

.payment-method {
  margin-top: 20px;

  h2 {
    color: $zenblue;
    font-size: 22px;
    font-weight: 550;
  }

  p {
    font-size: 20px;
    color: $gray2;
    text-wrap: nowrap;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  input {
    height: 15px;
    width: 200px;
  }

  .payment-button {
    margin-top: 20px;
    cursor: pointer;
  }
}

.payment-modal {
  position: relative;
  width: 300px;
  border: 2px solid $zenblue;
  border-radius: 10px;

  .close-modal-button {
    position: absolute;
    top: 4px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: #ff0000; // ou outra cor para hover
    }
  }

  select {
    font-size: 20px;
    border-radius: 10px;
    border: 1px solid $zenblue;
    color: $gray2;
    background: $white;
  }
}
