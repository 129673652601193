@import "../../../../styles/utils/Mixins.scss";
@import "../../../../styles/utils/ZIndex.scss";
@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/base/typography.scss";

.task-list-container,
.phoneListContainer {
  display: flex;
  justify-content: flex-start;
  padding: 50px 0px 50px 50px;
  margin-top: 50px;
  @include z-index(base);

  @include mq(desktop) {
    justify-content: center;
    padding: 50px 0px;

    .tasklist-content {
      max-width: 100%;
    }
  }
}

.tasklist-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 95%;
}

.card-render-n-pic {
  display: flex;
  @include z-index(base);

  @include start-column;
}

.card-render-n-pic--resized {
  display: flex;
  @include start-row;
}

.card-img {
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.3s ease, border 0.3s ease;
  width: 30px;

  &:hover {
    border: 5px solid $zenblue;
    transform: scale(1.1);
  }
}

.card-img--resized {
  width: 50px;
}

.transform--none {
  transform: scale(1.5);
  margin-left: 50px !important;
}

.div__reminders-mother {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  @include z-index(reminders);

  @include mq(tablet) {
    flex-direction: row;
    @include z-index(reminders);
  }

  .div__reminders-today {
    width: 50%;
    @include z-index(base);
  }
  .other-scale-reminders {
    @include z-index(base);
  }
}

.task-list-container {
  background-color: $white;
  padding-bottom: 50px;
  margin-left: 0px;

  @include mq(desktop) {
    margin-left: 200px;
    flex-direction: column;

    h1 {
      font-size: $size2;
      font-weight: 500;
      color: $darkblue;
      margin: 30px 80px;
    }

    h2 {
      font-size: $size3;
      font-weight: 500;
      color: $darkblue;
      margin: 30px 0px;
    }

    .ulTasks {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      @include z-index(base);
    }
  }

  .task-nav-icon,
  .phone-nav-icon {
    width: 30px;
    margin: 7.5px;
    cursor: pointer;
  }

  .floating-add-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-image: url("../../../../assets/icons/add.png");
    background-size: cover;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    @include z-index(modal);
    transition: transform 0.3s ease, box-shadow 0.5s ease;
    border: none;

    &:hover {
      transform: scale(1.2);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.8);
    }
  }

  .task-nav-icon:hover,
  .phone-nav-icon:hover {
    width: 45px;
    margin: 0px;
  }

  .deleteTask,
  .editTask,
  .deletePhone {
    border: none;
    padding: 0;
  }

  /*-------------------------
|        Add Modal         |
--------------------------*/
  .addTitle {
    color: $zenblue;
    font-size: $size1;
    font-weight: 600;
  }

  tr {
    display: flex;
    flex-direction: row;
  }

  .TaskCheckbox {
    width: 30px;
    min-width: 30px;
    cursor: pointer;
  }
}
