@import "../../../../styles/base/Colors.scss";
@import "../../../../styles/base/typography.scss";
@import "../../../../styles/utils/Mixins.scss";

.updateYourData {
  display: flex;
  color: $darkblue;
  align-items: center;
  justify-content: center;
  font-size: $size3;
  font-weight: 600;
  background: $white;
  width: 100%;
  height: 50px;

  @include mq(tablet) {
    font-size: $size2;
    height: 80px;
  }

  @include mq(desktop) {
    width: 800px;
    margin-top: 50px;
  }
}

.edit-data {
  background-color: $white;
  color: $darkblue;
  display: flex;
  flex-direction: column;

  @include mq(tablet) {
    flex-direction: row;
    gap: 50px;
    width: 800px;
    align-items: center;
    justify-content: center;
  }

  .profile-section {
    text-align: center;
    margin-top: 50px;
    gap: 10px;

    @include mq(tablet) {
      margin-top: 0;
    }

    .profile-picture {
      border-radius: 10px;
      border: 2px solid $zenblue;
      height: 150px;
      width: 150px;
    }

    .change-picture {
      margin: 2px 2px 25px 2px;
      color: $darkblue;
      background: $white;
      border: 1px solid $darkblue;
      height: 30px;
      width: 200px;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }

  .edit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 50px;

    .submit-button {
      background: linear-gradient(to bottom, #4cc3cf, #33a3b4);
      color: $white;
      border: none;
      padding: 10px 40px;
      cursor: pointer;
      transition: background-color 0.3s;
      border-radius: 50px;
      height: 40px;
      min-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
  }

  .cancel-button {
    background: $white;
    color: $darkblue;
    border: 1px solid $darkblue;
    padding: 10px 40px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 50px;
    height: 40px;
    min-width: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
