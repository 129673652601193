@import "../../styles/utils/Mixins.scss";
@import "../../styles/utils/ZIndex.scss";
@import "../../styles/base/Colors.scss";
@import "../../styles/base/typography.scss";

.dashboard {
  display: flex;
  height: 100%;
  justify-content: center;
  background: $white;

  .buttonUser,
  .buttonAside {
    width: 100%;
    border-radius: 10px;
    background: none;
    width: 50px;
    cursor: pointer;
  }

  .user-dropdown {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 40px;
    padding: 5px 20px;
    border: 1px solid $lightblue;
    background: $white;
    border-radius: 10px;

    .dropdown-item {
      color: $darkblue;
      font-weight: 600;
      background: none;
      border: none;
    }
  }

  .container__dashboardHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 0px 15px;
    top: 0;
    background: $lightblue;
    border-bottom: 1px solid $white;

    @include mq(desktop) {
      height: 55px;
    }
  }
}

.headerLeft {
  display: flex;
  @include start-row;
}

.hamburger {
  background: none;
  border: none;
  padding: 20px;

  @include mq(desktop) {
    display: none;
  }
}

.hamburgerIcon {
  border: none;
  cursor: pointer;
  width: 40px;
}

.imgHamburger {
  width: 40px;
}

.dashboardMainContainer {
  width: 80vw;
  margin-top: 100px;

  @include mq(desktop) {
    display: none;
  }
}

.dropdownMenu {
  position: absolute;
  top: 90px;
  left: 0;
  width: 60%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  @include z-index(dropdown);

  .dropDownButton {
    display: block;
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.userIcon {
  width: 35px;
  cursor: pointer;
  border-radius: 50px;
}

.zDashLogoAndName {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 40px;
  gap: 1rem;
  @include z-index(modal);

  @include mq(mobile-large) {
    display: flex;
  }

  > h2 {
    color: $white;
    text-align: center;
  }

  > .h2Name {
    display: none;

    @include mq(mobile-large) {
      display: flex;
    }
  }

  @include mq(desktop) {
    justify-content: flex-start;
  }
}

.header__paralel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include z-index(header);
}

.nav__header {
  display: flex;
  justify-content: flex-end;
}

.zDashMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include mq(desktop) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.zDashAsideDesktop {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 100px;

  @include mq(desktop) {
    margin: 100px 20px 0px 20px;
    display: flex;
  }
}

.zDashButton {
  background: $lightblue;
  color: $white;
  border: none;
  border-radius: 10px;
  padding: 8px 30px;
  width: 250px;
  cursor: pointer;
}

.submit-button {
  background: linear-gradient(to bottom, #4cc3cf, #33a3b4);
  color: $white;
  border: none;
  padding: 10px 40px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 50px;
  height: 40px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
