@import "../../styles/base/typography.scss";
@import "../../styles/base/Colors.scss";

:root {
  --button-default-height: 60px;
  --font-size-menu-1: 1.6rem;
  --main-button-color-top: #4cc3cf;
  --main-button-color-botton: #33a3b4;
  --main-button-font-color: #d4ffff;
}

$color_1: var(--main-button-font-color);
$color_2: #333;
$color_3: var(--zen-color-darkblue);
$color_4: var(--zen-color-green);
$main-button-color-top: var(--main-button-color-top);
$main-button-color-bottom: var(--main-button-color-bottom);
$background-color_2: var(--color-brand-fire);
$background-color_3: transparent;
$background-color_4: #fff;
$background-color_5: #f5f5f5;
$font-weight: var(--font-weight-button-1);

.sign-in-btn {
  background: linear-gradient(to bottom,
      $main-button-color-top,
      $main-button-color-bottom );
  cursor: pointer;

  color: $color_1;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 10px;
  height: 50px;
  display: none;
}

.get-zenminder-btn-1 {
  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;

  margin: 5px;
  color: var(--font-color-title-2-white);
  background: var(--background-nav-center);
  border: 1px solid var(--font-color-title-1-dark);
  height: 30px;
  width: 100px;
  border-radius: 20px;
  font-weight: $font-weight;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-zenminder-btn-2 {
  background: linear-gradient(to bottom, #4cc3cf, #33a3b4);
  color: $color_1;
  border: none;
  padding: 10px 50px;
  text-decoration: none;
  text-wrap: nowrap;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 50px;
  height: 50px;
  font-weight: $font-weight;
  min-width: 280px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.button__hamburger {
  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;

  width: 50px;
  border: none;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
  background-color: $background-color_3;
  cursor: pointer;
  position: fixed;
  top: 25px;
  right: 20px;

  img {
    width: 50px;
  }
}

.nav__button {
  &:hover {
    opacity: 0.7;
  }

  cursor: pointer;

  margin: 5px;
  color: var(--font-color-title-1-dark);
  height: 30px;
  width: 100px;
  border-radius: 20px;
  font-weight: $font-weight;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button__home {
  img {
    width: 50px;
  }

  cursor: pointer;

  border: none;
  background-color: $background-color_3;
  cursor: pointer;
}

.button__hamburger--paralel {
  img {
    width: 50px;
  }

  border: none;
  background-color: $background-color_3;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 70px;
  align-items: center;
  justify-content: center;
}

.menu {
  display: none;
  flex-direction: column;
  gap: 20px;
  position: fixed;
  top: 80px;
  right: 2%;
  background-color: $background-color_4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  border: 2px solid var(--zen-color-green);
  cursor: pointer;

  a {
    display: block;
    text-decoration: none;
    color: $color_2;
    padding: 5px 10px;
    border-radius: 3px;
    transition: background-color 0.3s;
    color: $color_3;
    cursor: pointer;

    &:hover {
      background-color: $background-color_5;
    }
  }

  P {
    color: $color_4;
    opacity: 0.8;
    padding-bottom: 15px;
  }
}

button {
  font-size: var(--font-size-button-1);
  line-height: var(--line-height-button-1);
  font-weight: $font-weight;
  border-radius: 20px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $darkblue;
  margin: 2px solid $darkblue;
  padding: 5px 30px;
  cursor: pointer;

}

.get-zenminder-btn-2 {
  font-size: var(--font-size-button-1);
  line-height: var(--line-height-button-1);
  font-weight: $font-weight;
  cursor: pointer;

}

@media screen and (min-width: 400px) {
  :root {
    --font-size-button-1: 1.3rem;
  }

  .button__hamburger {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --font-size-button-1: 1.5rem;
  }

  .button__hamburger {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --font-size-button-1: 1.5rem;
  }

  .button__home {
    top: 5px;
    left: 10px;

    img {
      width: 45px;
    }
  }

  .button__hamburger {
    display: none;

    img {
      width: 60px;
    }
  }

  .get-zenminder-btn-1 {
    top: 14px;
    right: 150px;
    display: flex;
  }

  .menu {
    position: fixed;
    top: 80px;
    right: 5%;
  }
}