@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Nunito+Sans:opsz,wght@6..12,700;6..12,800&display=swap";

:root {
  --font-size-title-1: 4rem;
  --font-size-title-2: 20px;
  --font-size-title-3: 1.8rem;
  --font-size-title-4: 1.6rem;
  --font-size-text-1: 1.4rem;
  --font-size-text-2: 15px;
  --font-size-text-3: 20px;
  --font-size-text-4: 1.1rem;
  --font-size-text-5: 1rem;

  --line-height-title-1: 140%;
  --line-height-title-2: 160%;
  --line-height-title-3: 160%;
  --line-height-text-1: 150%;
  --line-height-text-2: 150%;
  --line-height-text-3: 150%;
  --line-height-button-1: 150%;
  --line-height-menu-1: 150%;
  --font-weight-title-1: 800;
  --font-weight-title-2: 600;
  --font-weight-title-3: 500;
  --font-weight-text-1: 500;
  --font-weight-text-2: 450;
  --font-weight-text-3: 400;
  --font-weight-button-1: 600;
  --font-weight-menu-1: 600;
}

$size1: var(--font-size-title-1);
$size2: var(--font-size-title-2);
$size3: var(--font-size-title-3);
$size4: var(--font-size-title-4);
$size5: var(--font-size-text-1);
$size6: var(--font-size-text-2);
$size7: var(--font-size-text-3);
$size8: var(--font-size-text-4);
$size9: var(--font-size-text-5);

.div__slogan {
  >h1 {
    font-size: var(--font-size-title-1);
    line-height: var(--line-height-title-1);
    font-weight: var(--font-weight-title-1);
  }

  >h2 {
    font-size: var(--font-size-title-4);
    font-weight: var(--font-weight-title-1);
    line-height: var(--line-height-title-3);
  }

  >p {
    font-size: var(--font-size-text-4);
    line-height: var(--line-height-text-1);
    font-weight: var(--font-weight-text-1);
  }
}

.text__section {
  >h1 {
    font-size: var(--font-size-title-2);
    line-height: var(--line-height-title-2);
    font-weight: var(--font-weight-title-2);
  }

  >h2 {
    font-size: var(--font-size-title-3);
    line-height: var(--line-height-title-3);
    font-weight: var(--font-weight-title-2);
  }

  >ul {
    >li {
      font-size: var(--font-size-title-3);
      line-height: var(--line-height-title-3);
      font-weight: var(--font-weight-title-2);
    }
  }

  >h3 {
    font-size: var(--font-size-text-1);
    line-height: var(--line-height-text-1);
    font-weight: var(--font-weight-text-1);
  }

  >p {
    font-size: var(--font-size-text-2);
    line-height: var(--line-height-text-2);
    font-weight: var(--font-weight-text-2);
  }
}

.li__values {
  >p {
    font-size: var(--font-size-text-2);
    line-height: var(--line-height-text-2);
    font-weight: var(--font-weight-text-2);
  }
}

.modal-container {
  >h2 {
    font-size: var(--font-size-title-3);
    line-height: var(--line-height-title-3);
    font-weight: var(--font-weight-title-2);
  }
}

.div__logo-and-name {
  >h2 {
    font-size: var(--font-size-text-3);
    line-height: var(--line-height-title-3);
    font-weight: var(--font-weight-title-2);
  }
}

.zDashLogoAndName {
  >h2 {
    font-size: var(--font-size-text-3);
    line-height: var(--line-height-title-3);
    font-weight: var(--font-weight-title-2);
  }
}

.text__main {
  >article {
    font-size: var(--font-size-text-3);
    line-height: var(--line-height-text-3);
    font-weight: var(--font-weight-text-3);
  }
}

.ul__values {
  >article {
    font-size: var(--font-size-text-3);
    line-height: var(--line-height-text-3);
    font-weight: var(--font-weight-text-3);
  }
}

.menu {
  a {
    font-size: var(--font-size-menu-1);
    line-height: var(--line-height-menu-1);
    font-weight: var(--font-weight-menu-1);
  }
}

@media screen and (min-width: 480px) {
  :root {
    --font-size-title-1: 5rem;
    --font-size-title-2: 2.4rem;
    --font-size-title-3: 1.85rem;
    --font-size-text-1: 2rem;
    --font-size-text-2: 1.6rem;
    --font-size-text-3: 1.4rem;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --font-size-title-1: 7rem;
    --font-size-title-2: 3rem;
    --font-size-title-3: 2.2rem;
    --font-size-text-1: 2.3rem;
    --font-size-text-2: 1.9rem;
    --font-size-text-3: 1.6rem;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --font-size-title-1: 4rem;
    --font-size-title-2: 2.5rem;
    --font-size-title-3: 1.5rem;
    --font-weight-title-3: 600;
    --font-size-text-1: 1.8rem;
    --font-size-text-2: 1.6rem;
    --font-size-text-3: 1.3rem;
  }
}